import React from 'react'
import PropTypes from 'prop-types'
//import ahdcSprite from '../icons/ahdc-sprite.svg'

const AHDCIcon = ({ name, color, size, ...restProps }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="#17ac52"
    stroke={color}
    strokeWidth="0"
    strokeLinecap="round"
    strokeLinejoin="round"
    {...restProps}
  >
    <use xlinkHref={`/images/backgrounds/ahdc-sprite.svg#${name}`} />
  </svg>
)

export default AHDCIcon

AHDCIcon.propTypes = {
  name: PropTypes.string.isRequired,
  color: PropTypes.string,
  size: PropTypes.number,
}

AHDCIcon.defaultProps = {
  color: 'currentColor',
  size: 35,
}