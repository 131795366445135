import React from "react";

const QuoteOpen = props => (
  <svg xmlns="http://www.w3.org/2000/svg" width="90.6" height="73.5" viewBox="0 0 90.6 73.5">
    <path data-name="Path 1374" d="M85.2 0C66.6 5.7 56.1 24 56.1 45v28.5H90V39.6H74.1c0-11.7 4.5-23.7 16.5-30.6zM29.1 0C10.5 5.7 0 24 0 45v28.5h33.9V39.6H18C18 27.9 22.5 15.9 34.5 9z" fill="#17ac53"/>
  </svg>
);

export default QuoteOpen;

