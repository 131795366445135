import React, {useState} from "react";
import { animated, useSpring } from "react-spring";
import { Waypoint } from "react-waypoint";

const BarExpand = (props) => {
  const [on, toggle] = useState(false);
  const barexpand = useSpring({
    width: on ? `${props.width}%` : `0%`,
    from: { width: `0%` },
    to: {
      width: on ? `${props.width}%` : `0%`,
    },
    config: { duration: 500 }
  })
  
  const fadein = useSpring({
      opacity: on ? 1 : 0,
      from: { opacity: 0 },
      to: {
        opacity: on ? 1 : 0
      },
      delay: 500,
      config: { duration: 400 }
    });
    
  return (
    <>
    <Waypoint
    bottomOffset="25%"
      onEnter={() => {
        if (!on) toggle(true);
      }}
    />
    <h2 className={props.class}><animated.span style={barexpand} className="block-green"></animated.span><animated.span style={fadein} className={props.spanclass}>{props.text}</animated.span></h2>
    </>
  )
}

export default BarExpand
