import React from "react";

const QuoteClose = props => (
  <svg xmlns="http://www.w3.org/2000/svg" width="89.1" height="73.5" viewBox="0 0 89.1 73.5">
    <path data-name="Path 1375" d="M34.5 28.5V0H.6v33.9h16.2c0 12-4.5 24-16.8 30.6l5.4 9c18.9-5.7 29.1-24 29.1-45zm54.6 0V0H55.2v33.9h16.2c0 12-4.8 24-16.5 30.6l5.1 9c18.9-5.7 29.1-24 29.1-45z" fill="#17ac53"/>
  </svg>
);

export default QuoteClose;

