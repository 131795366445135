import React, {useState} from "react";
import { animated, useSpring } from "react-spring";
import { Waypoint } from "react-waypoint";

const BarExpandSpan = (props) => {
  const [on, toggle] = useState(false);
  const barexpand = useSpring({
    width: on ? `${props.width}%` : `0%`,
    from: { width: `0%` },
    to: {
      width: on ? `${props.width}%` : `0%`,
    },
    config: { duration: 800 }
  })
    
  return (
    <>
    <Waypoint
    bottomOffset="25%"
      onEnter={() => {
        if (!on) toggle(true);
      }}
    />
    <animated.span style={barexpand} className={props.class}></animated.span>
    </>
  )
}

export default BarExpandSpan
