import React, { useState } from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import { Row, Col } from "reactstrap";
import { animated, useTrail } from "react-spring";
import { Waypoint } from "react-waypoint";

const PortfolioGrid = () => {
  const data = useStaticQuery(graphql`
    query PortfolioQuery {
      wpgraphql {
        cpt_properties(where: { orderby: { field: MENU_ORDER, order: ASC } }) {
          nodes {
            title
            slug
            acf_properties {
              address {
                city
                state
              }
              details {
                units
                projectType
              }
              thumbnail {
                sourceUrl
                imageFile {
                  childImageSharp {
                    fluid(quality: 100) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  const properties = data.wpgraphql.cpt_properties.nodes;
  //const isHomepage = location.pathname === withPrefix("/")
  const [on, toggle] = useState(false);

  const trail = useTrail(properties.length, {
    from: { opacity: 0, transform: "translate(0px,100px)" },
    to: {
      opacity: on ? 1 : 0,
      transform: on ? "translate(0px,0px)" : "translate(0px,100px)"
    }
  });

  return (
    <section className="portfolio__grid mt-3">
      <Waypoint
        topOffset="25%"
        onEnter={() => {
          if (!on) toggle(true);
        }}
      />
      {trail.map(({ ...animation }, index) => (
        <animated.div key={index} className="portfolio__item" style={{ ...animation }}>
          {properties[index].acf_properties.thumbnail != null && (
            <a href={`${properties[index].slug}`}>
              <div className="portfolio__thumbnail">
                <div
                  className="portfolio__thumbnail-image"
                  style={{
                    backgroundImage: `url(${properties[index].acf_properties.thumbnail.imageFile.childImageSharp.fluid.src})`
                  }}
                >
                </div>
                <div className="portfolio__projectype">{properties[index].acf_properties.details.projectType}</div>
              </div>
            </a>
          )}

          <Row className="portfolio__item-details">
            <Col xs={{ size: 10 }} className="px-0">
              <a href={`${properties[index].slug}`}>
                <h2 className="portfolio__item-name">
                    {properties[index].title}
                </h2>
                <p>
                  {properties[index].acf_properties.address.city},{" "}
                  {properties[index].acf_properties.address.state}
                </p>
              </a>
            </Col>
            <Col xs={{ size: 2 }} className="portfolio__unit-mix px-0">
              <h2>Units</h2>{" "}
              <p>
                {properties[index].acf_properties.details.units}
              </p>
            </Col>
          </Row>
        </animated.div>
      ))}
    </section>
  );
};

export default PortfolioGrid;
