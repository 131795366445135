import React, { useState } from "react";
import { animated, useTrail } from "react-spring";
import { Waypoint } from "react-waypoint";
import AHDCIcon from '../img/icons/ahdc-icons'
import content from './bottomline_data';

const BottomLineGrid = () => {
  

  const [on, toggle] = useState(false);

  const trail = useTrail(content.length, {
    from: { opacity: 0, transform: "translate(0px,100px)" },
    to: {
      opacity: on ? 1 : 0,
      transform: on ? "translate(0px,0px)" : "translate(0px,100px)"
    },
    config: { delay: 50 }
  });

  return (
    <>
      <Waypoint
        bottomOffset="25%"
        onEnter={() => {
          if (!on) toggle(true);
        }}
      />
      {trail.map(({ ...animation }, index) => (
        <animated.div key={content[index].icon} className="col-md-4 px-0 text-center pt-3" style={{ ...animation }}>

        <h3><AHDCIcon name={content[index].icon} color="#17ac52" /> <span>{content[index].word}</span></h3>

        </animated.div>
      ))}
    </>
  );
};

export default BottomLineGrid;
