import React, {useState} from 'react';
import { graphql, withPrefix } from 'gatsby'
import Layout from '../components/layout'
import SEO from "../components/seo";
import Img from "gatsby-image"
import PortfolioGrid from "../components/portfolio/portfolio-grid";
import GoogleMap from "../components/map/googleMap";
//import GoogleMapContainer from "../components/map/googlemapcluster";
import { Container, Row, Col } from 'reactstrap'
import Quotes from "../components/quotes"
import { animated, useSpring } from "react-spring";
import { Waypoint } from "react-waypoint";
import BottomLineGrid from '../components/portfolio/triple-bottom'
import BarExpand from "../components/img/bar-expand"
import BarExpandSpan from "../components/img/bar-expand-span"

export const query = graphql`
  query {
    wpgraphql {
      page(id: "cG9zdDoxMA==") {
          title
          content
          featuredImage {
            node {
              altText
              sourceUrl
              imageFile {
                childImageSharp {
                  fluid(quality: 75, maxWidth:1900) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
        acf_page_meta {
          metadescription
          metatitle
        }
        acf_portfolio_page {
       bottomLineImage {
         altText
         sourceUrl
         imageFile {
           childImageSharp {
             fluid {
             ...GatsbyImageSharpFluid
           }
         }
       }
       }
     }
      }
    }
  }
`
const Portfolio = ({ data, location }) => {
  const [on, toggle] = useState(false);

    const fadein = useSpring({
        opacity: on ? 1 : 0,
        from: { opacity: 0, transform: "translate3d(0,50px,0)" },
        to: {
          opacity: on ? 1 : 0,
          transform: on ? "translate3d(0,0,0px)" : "translate3d(0,50px,0)"
        },
        config: { tension: 100 }
      });
  const page = data.wpgraphql.page
  return (

    <Layout navlocation={location.pathname}>
    <SEO
          title={data.wpgraphql.page.acf_page_meta.metatitle}
          description={data.wpgraphql.page.acf_page_meta.metadescription}
        />
    <Waypoint
      onEnter={() => {
        if (!on) toggle(true);
      }}
    />
      <header className="header--hero header-left">
        {data.wpgraphql.page.featuredImage != null && (
          <Img
            className="header__image"
            alt={ page.featuredImage.node.altText }
            fluid={ page.featuredImage.node.imageFile.childImageSharp.fluid }
          />
        )}

        <Container fluid={true} className="container__page">
          <Row>
            <Col md={{ size: 7 }} className="px-0">
            <animated.div style={fadein}>
              <h1 className="heading1 long-heading ml-1 ml-md-3">
              Revitalizing Neighborhoods and <b>Building Hope.</b>
              </h1>
              </animated.div>
              <BarExpandSpan width="100" class="header--block block-left" />

            </Col>
          </Row>
        </Container>
      </header>

      <Row className="home__card">
        <Col md={{ offset: 1, size: 10}}  className="px-md-2 px-lg-0">
          <section className="home__standards">
            <div dangerouslySetInnerHTML={{ __html: page.content }}/>
          </section>
        </Col>
      </Row>
      
      <Row className="home__card_bottomline align-content-start">
      <Col md={{ size: 12}} className="pt-3">
      <BarExpand width="10" text="Our Triple Bottom Line" class="text-white" spanclass="service-header"/>
      </Col>
      <BottomLineGrid/>
      </Row>
      
    <Row className="portfolio__card_wrapper bg-light">
            <Col md={{ size: 12}} className="py-3 pl-0">
                  <BarExpand width="15" text="Project Highlights" class="text-secondary" spanclass="service-header"/>

            </Col>
            <Col sm={{size: 12}} lg={{offset: 2, size: 8}}>
            <PortfolioGrid />
            </Col>
          </Row>
  
          <Row>
                  <Col md={{ size: 12 }} className="col__property-map">
    <div className="section__property-map mapcontainer">
    <GoogleMap />

          </div>
          </Col>
          </Row>
            
  
      
      <Row className="d-flex home__card_full_list_projects">
      <Col className="align-self-center text-center">
      <h2 className="">See our full list of projects</h2>
      <br/>
    
          <a
            className="btn btn-outline-info"
            rel="noopener noreferrer"
            href={withPrefix("/pdfs/AHDC-PROJECT-LIST.pdf")}
            target="_blank"
          >Download PDF</a>
      </Col>
      </Row>
      

      <Quotes id="cG9zdDoyMDE=" />

    </Layout>
  )
}

export default Portfolio
